
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGrid } from 'devextreme-vue/ui/data-grid';
import { IDxInstance } from '@/types';
import { DxDateBox, DxSelectBox, DxTagBox } from 'devextreme-vue';
import { IReportService, ReportServiceFactory } from '@/api';
import ReportParameterForm from '@/views/reports/ReportParameterForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import config from './config';

@Component({
  name: 'RedemptionSummary',
  components: {
    'gx-bc': Breadcrumbs,
    'gx-rpf': ReportParameterForm
  }
})
export default class RedemptionSummary extends Vue {
  public $refs: Vue['$refs'] & { grid?: DxDataGrid } = {};
  private columns: any = config.columns;
  private load: boolean = false;
  private totals: any[] = [0, 0];
  private exportFileName: string =
    'RedemptionSummary_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().toLocaleTimeString();
  private reportService: IReportService = ReportServiceFactory.getInstance() as IReportService;
  private dataSource: any = {
    store: new CustomStore({
      load: loadOptions => {
        if (!this.load) {
          this.load = true;
          return Promise.resolve({ data: [], totalCount: 0 });
        }
        return this.fetchData(loadOptions);
      }
    })
  };
  // ref getter
  private get grid() {
    return (this.$refs.grid as IDxInstance).instance;
  }

  private onContentReady() {
    this.totals = [0];
    //this.totals[0] = this.grid.getTotalSummaryValue("");
    

    //this.$emit("update", this.totals);
  }

  async fetchData(loadOptions: any): Promise<any> {
    loadOptions.totalSummary = config.summary;
    let response = await this.reportService.getRedemptionSummaryReport(
      loadOptions
    );
    if (
      response == undefined ||
      response == '' ||
      response.summary == undefined
    ) {
      response.summary = [0];
    }
    return response;
  }

  public onExportToExcel() {
    this.grid.exportToExcel(false);
  }

  public onShowHideColumns() {
    this.grid.showColumnChooser();
  }

  public onViewReport(args: any) {
    this.grid.getDataSource().filter(args.filters);
    this.grid.getDataSource().reload();
  }
}
