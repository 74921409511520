export const columns = [
    {
      dataField: 'RedeemptionDate',
      caption: 'Period Date',
      dataType: 'datetime',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'AgentName',
        caption: 'Agent',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'RedeemptionTimePeriod',
      caption: 'Redemption Time Period',
      dataType: 'hour',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'DeviceName',
        caption: 'Device Name',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'SellerName',
      caption: 'Seller',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'ProviderName',
      caption: 'Provider',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'ProductName',
        caption: 'Product Name',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'BundleName',
      caption: 'Bundle Name',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'RowCount',
        caption: 'Row Count',
        dataType: 'number',
        alignment: 'left',
        visible: true,
        allowFiltering: true
      },

    {
        dataField: 'RetailPrice',
        caption: 'Retail Amount',
        dataType: 'number',
        format: { type: 'currency', precision: 2 },
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'TaxAmount',
        caption: 'Tax',
        dataType: 'number',
        format: { type: 'currency', precision: 2 },
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'CompAmount',
      caption: 'Comp Price',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
      alignment: 'left',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'CompCount',
      caption: 'Comp Count',
      dataType: 'number',
      alignment: 'left',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'Redeemer',
        caption: 'Redeemer',
        dataType: 'string',
        visible: true,
        allowFiltering: true
      },
    
  ];
  
  let summary = [
    { selector: 'RetailPrice', summaryType: 'sum' },
    { selector: 'CompAmount', summaryType: 'sum' },
    { selector: 'CompCount', summaryType: 'sum' },
    { selector: 'TaxAmount', summaryType: 'sum' },
    { selector: 'RowCount', summaryType: 'sum' }
  ];
  
  export default { columns: columns, summary: summary };
  