import { IParameterService } from './IParameterService';
import { AxiosClient } from '@/client/axios';
import axios from 'axios';
import { CurrentEnvironment, ICurrentEnvironment } from '@/config/environment';

export class ParameterService implements IParameterService {
  private client: AxiosClient;
  private env: ICurrentEnvironment = new CurrentEnvironment() as ICurrentEnvironment;
  constructor() {
    this.client = new AxiosClient();
    const accessToken = sessionStorage.getItem('accessToken');
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Authorization: 'Bearer ' + accessToken,
        };
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  public async getAgents(loadOptions: any, collection: string) {
    let result: any = [];
    const baseURL =  this.env.functionAppbaseUrl + 'get-static-parameters?name=' + collection;
    
    await this.client
      .post(
        baseURL,
        {
          loadOptions : {
            ...loadOptions,
            group: [{
              selector: 'AgentName',
            }],
          },
        },
      )
      .then(function(response) {
        result = response.data.data
          .map((item: any, index: number) => ({name: item.key, id: index}))
          .filter((item: any) => !!item.name)
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }

  public async getSellers(loadOptions: any) {
    return await this.getParameters(loadOptions, 'sellers');
  }

  public async getChannels(loadOptions: any) {
    return await this.getParameters(loadOptions, 'channels');
  }

  public async getProviders(loadOptions: any) {
    return await this.getParameters(loadOptions, 'seller-provider');
  }

  public async getVenues(loadOptions: any) {
    return await this.getParameters(loadOptions, 'venues');
  }

  public async getTurnstileAddress(loadOptions: any) {
    let queryParam = 'GetTurnstile';
    return await this.getParameters(loadOptions, queryParam);
  }

  public async getProducts(loadOptions: any, collection: string) {
    let result: any = [];
    const baseURL =  this.env.functionAppbaseUrl + 'get-static-parameters?name=' + collection;

    await this.client
      .post(
        baseURL,
        {
          loadOptions : {
            ...loadOptions,
            group: [{
              selector: 'ProductName',
            }],
          },
        },
      )
      .then(function(response) {
        result = response.data.data
          .map((item: any, index: number) => ({name: item.key, id: index}))
          .filter((item: any) => !!item.name)
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }

  private async getParameters(loadOptions: any, dbName: string) {
    let result: any = [];
    const baseURL =  this.env.functionAppbaseUrl + 'get-static-parameters?name=' + dbName;

    await this.client
      .post(baseURL, {loadOptions})
      .then(function(response) {
        result = response.data.data;
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }

  public async getInventoryCalendar(loadOptions: any) {
    let result: any = [];
    let baseURL = 'api/report/InventoryCalendar';

    await this.client
      .post(baseURL, loadOptions)
      .then(function(response) {
        result = response.data;
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }
}
