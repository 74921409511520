export const columns = [
  {
    dataField: 'Hour',
    caption: 'Hour',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'Description',
    caption: 'Status Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'Scan',
    caption: 'Scan',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'Overrides',
    caption: 'Overrides',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  }
];
