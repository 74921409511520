
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { DxDateBox, DxSelectBox, DxTagBox } from 'devextreme-vue';
import { IParameterService } from '@/api/IParameterService';
import { IDxInstance } from '@/types';
import { ParameterServiceFactory } from '@/api/ParameterServiceFactory';

@Component({ name: 'ReportParameterForm' })
export default class ReportParameterForm extends Vue {
  @Prop({ required: true })
  private startDateType!: string;
  @Prop({ required: true })
  private endDateType!: string;
  @Prop({ required: true })
  private includeSeller!: boolean;
  @Prop({ required: false })
  private inventoryPageCall!: boolean;
  @Prop({ required: true })
  private includeProviders!: boolean;
  @Prop({ required: true })
  private includeAgents!: boolean;
  @Prop({ required: true })
  private includeChannels!: boolean;
  @Prop({ required: true })
  private includeProducts!: boolean;
  @Prop({ required: true })
  private dateColumnName!: string;
  @Prop({ required: true })
  private channelColumnName!: string;
  @Prop({ required: true })
  private providerColumnName!: string;
  @Prop({ required: true })
  private summaryColumnOne!: string;
  @Prop({ required: true })
  private summaryColumnTwo!: string;
  @Prop({ required: true })
  private summaryColumnThree!: string;
  @Prop({ required: false })
  private includesummaryColumnThree!: boolean;
  @Prop({ required: false })
  private turnStileType!: string;
  @Prop({ required: false })
  private includeDateFilter!: boolean;
  @Prop({ required: false })
  private includeTurnstileAddress!: boolean;
  @Prop({ required: false })
  private includeTurnstileVenues!: boolean;
  @Prop({ required: false })
  private reportName!: string;
  @Prop({ required: false })
  private collectionName!: string;
  @Prop({ required: false })
  private includeDateAndTimeFilter!: boolean;
  @Prop({ required: false })
  private includeOnlyChannels!: boolean;

  private parameterService: IParameterService = ParameterServiceFactory.getInstance() as IParameterService;
  /*
    Setup vue references so we can reference them in code
  */
  public $refs: Vue['$refs'] & {
    startRef?: DxDateBox;
    endRef?: DxDateBox;
    startTimeRef?: DxDateBox;
    endTimeRef?: DxDateBox;
    sellerRef?: DxSelectBox;
    providersRef?: DxTagBox;
    agentsRef?: DxTagBox;
    channelsRef?: DxTagBox;
    productsRef?: DxTagBox;
    turnstileAddressRef?: DxSelectBox;
    venueRef?: DxSelectBox;
  } = {};

  public minStartDate: any = null;

  public includesummaryColumnThreeDuplicated: any = null;
  public includeDateAndTimeFilterDuplicated: any = null;
  public includeDateFilterDuplicated: any = null;
  public start: any = null;
  public end: any = null;
  private selectedSeller: any = {};
  private selectedTurnstileAddress: any = {};
  private selectedVenue: any = {};

  private reportSummary: Array<any> = [];
  private rptSummaryColOneValue: any = null;
  private rptSummaryColTwoValue: any = null;
  private rptSummaryColThreeValue: any = null;

  private viewReportBtnDisable = true;

  private sellersDataSource: Array<any> = [];

  private providersDataSource: Array<any> = [];

  private agentsDataSource: Array<any> = [];

  private channelsDataSource: Array<any> = [];

  private productsDataSource: Array<any> = [];

  private turnstileAddressDataSource: Array<any> = [{ id: 0, name: 'All' }];
  private venuesDataSource: Array<any> = [];

  private isLoadingAgents: boolean = false;
  private isLoadingSellers: boolean = false;
  private isLoadingVenues: boolean = false;
  private isLoadingProviders: boolean = false;
  private isLoadingChannels: boolean = false;
  private isLoadingProducts: boolean = false;

  // ref getters
  private get startDate() {
    return (this.$refs.startRef as IDxInstance).instance;
  }
  private get endDate() {
    return (this.$refs.endRef as IDxInstance).instance;
  }
  private get startTime() {
    return (this.$refs.startTimeRef as IDxInstance).instance;
  }
  private get endTime() {
    return (this.$refs.endTimeRef as IDxInstance).instance;
  }
  private get seller() {
    return (this.$refs.sellerRef as IDxInstance).instance;
  }
  private get providers() {
    return (this.$refs.providersRef as IDxInstance).instance;
  }
  private get agents() {
    return (this.$refs.agentsRef as IDxInstance).instance;
  }
  private get channels() {
    return (this.$refs.channelsRef as IDxInstance).instance;
  }
  private get products() {
    return (this.$refs.productsRef as IDxInstance).instance;
  }
  private get turnstileAddress() {
    return (this.$refs.turnstileAddressRef as IDxInstance).instance;
  }
  private get turnstileVenues() {
    return (this.$refs.venueRef as IDxInstance).instance;
  }

  mounted() {
    if (this.reportName == 'InventoryCalendar') {
      this.minStartDate = new Date();
      this.minStartDate.setDate(this.minStartDate.getDate() - 7);
    }

    this.start = new Date();
    this.start.setHours(0, 0, 0, 0);

    let end = new Date();
    end.setDate(end.getDate() + 1);
    end.setHours(0, 0, 0, 0);
    end.setMinutes(end.getMinutes() - 1);
    end.setSeconds(59);
    this.end = end;
    this.reportSummary = [0, 0, 0];

    let includesummaryColumnThreeDuplicated = this.includesummaryColumnThree;
    if (this.includesummaryColumnThree == null) {
      includesummaryColumnThreeDuplicated = true;
    }
    this.includesummaryColumnThreeDuplicated = includesummaryColumnThreeDuplicated;

    let includeDateFilterDuplicated = this.includeDateFilter;
    if (this.includeDateFilter == null) {
      includeDateFilterDuplicated = true;
    }
    this.includeDateFilterDuplicated = includeDateFilterDuplicated;

    let includeDateAndTimeFilterDuplicated = this.includeDateAndTimeFilter;
    if (this.includeDateAndTimeFilter == null) {
      includeDateAndTimeFilterDuplicated = false;
    }
    this.includeDateAndTimeFilterDuplicated = includeDateAndTimeFilterDuplicated;
    this.collectionName = this.collectionName;
  }
  public insert = (arr: any, index: any, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ];

  public loadReportSummary(totals: any) {
    if (totals[0] != null) {
      this.rptSummaryColOneValue = totals[0];
    }
    if (totals[1] != null) {
      this.rptSummaryColTwoValue = totals[1];
    }
    if (totals[2] != null) {
      this.rptSummaryColThreeValue = totals[2];
    }
  }
  created() {
    this.$parent && this.$parent.$on('update', this.loadReportSummary);
    this.loadParameters();
    if (this.includeOnlyChannels && !this.isLoadingChannels) {
      this.isLoadingChannels = true;
      this.parameterService.getChannels({filter: [['MemberVenueId', '=', 41]]})
        .then(data => {
          this.channelsDataSource = data.map((item: any) => ({
            id: item.SalesTerminalTypeId,
            name: item.Name
          }));
        })
        .finally(() => this.isLoadingChannels = false);
    }
  }
  public loadParameters() {
    if (!this.isLoadingSellers) {
      this.isLoadingSellers = true;
      this.parameterService.getSellers(null)
        .then(data => {
          this.sellersDataSource = data.map((item: any) => ({
            id: item.SellerId,
            name: item.Name
          }));
          if (this.sellersDataSource.length > 0) {
            this.selectedSeller = this.sellersDataSource[0];
            this.viewReportBtnDisable = false;
          }
        })
        .finally(() => this.isLoadingSellers = false);
    }

    this.viewReportBtnDisable = true;

    if (!this.isLoadingVenues) {
      this.isLoadingVenues = true;
      this.parameterService.getVenues(null)
        .then(data => {
          this.venuesDataSource = data.map((item: any) => ({
            id: item.MemberVenueId,
            name: item.Name
          }));
        })
        .finally(() => {
          this.viewReportBtnDisable = false;
          this.isLoadingVenues = false;
        });

    }

    if (!this.includeSeller) {
      let filters = {filter :[['SellerId', '=', 41]]};
      if (this.includeProviders && !this.isLoadingProviders) {
        this.viewReportBtnDisable = true;
        this.isLoadingProviders = true;
        this.parameterService.getProviders(filters)
          .then(data => {
            this.providersDataSource = data.map((item: any) => ({
              providerId: item.ProviderId,
              providerName: item.ProviderName 
            }));
            this.viewReportBtnDisable = false;
            if (this.includeProducts) {
              this.providersDataSource.forEach(provider => {
                filters.filter.push(provider.providerId);
              });
              this.viewReportBtnDisable = true;
              this.parameterService.getProducts(filters, this.collectionName)
                .then((data: any) => {
                  this.productsDataSource = data;
                })
                .finally(() => {
                  this.viewReportBtnDisable = false;
                })
            }
          })
          .catch(() => {
            this.viewReportBtnDisable = false
          })
          .finally(() => this.isLoadingProviders = false);
      }
    }
  }
  public sellerDataSourceChange() {
    const sellerId = this.seller.option('value').id;

    if (sellerId !== undefined) {
      const filters: any = {filter: [['SellerId', '=', sellerId]]};

      if (this.includeProviders && !this.isLoadingProviders) {
        this.isLoadingProviders = true;
        this.parameterService.getProviders(filters)
          .then(data => {
            this.providersDataSource = data.map((item: any) => ({
              providerId: item.ProviderId,
              providerName: item.ProviderName 
            }));
          })
          .finally(() => this.isLoadingProviders = false);
      }
      if (this.includeChannels && !this.isLoadingChannels) {
        this.isLoadingChannels = true;
        this.parameterService.getChannels({filter: [['MemberVenueId', '=', sellerId]]})
          .then(data => {
            this.channelsDataSource = data.map((item: any) => ({
              id: item.SalesTerminalTypeId,
              name: item.Name
            }));
          })
          .finally(() => this.isLoadingChannels = false);
      }
      if (this.includeProducts && !this.isLoadingProducts) {
        this.isLoadingProducts = true;
        this.parameterService.getProducts(filters, this.collectionName)
          .then((data: any) => {
            this.productsDataSource = data;
          })
          .finally(() => {
            this.isLoadingProducts = false
          });
      }
      if (this.includeAgents) {
        this.LoadAgentData(sellerId);
      }
    }
  }

  public onStartDateChange() {
    if (this.includeAgents) {
      let sellerId = this.seller.option('value').id;
      this.LoadAgentData(sellerId);
    }
  }
  public onEndDateChange() {
    if (this.includeAgents) {
      let sellerId = this.seller.option('value').id;
      this.LoadAgentData(sellerId);
    }
  }
  private LoadAgentData(sellerId: any) {
    if (this.isLoadingAgents) {
      return;
    }
    let loadOptionsAgent: any;
    loadOptionsAgent = {
      filter: [
        ['PurchaseDate', '>=', this.startDate.option('value')],
        ['and'],
        ['PurchaseDate', '<', this.endDate.option('value')],
        ['and'],
        ['SellerId', ': ', sellerId]
      ],
    };
    this.isLoadingAgents = true;
    this.parameterService.getAgents(loadOptionsAgent, this.collectionName)
      .then((data: any) => {
        this.agentsDataSource = data;
      })
      .finally(() => this.isLoadingAgents = false);
  }

  public onViewReport() {
    let filters = Array<any>();

    if (this.includeSeller) {
      if (this.seller.option('value').name != undefined) {
        filters.push(['SellerId', '=', this.seller.option('value').id]);
        filters.push('and');
      }
    }

    if (
      this.includeTurnstileVenues &&
      this.turnstileVenues.option('value').id > 0
    ) {
      var id2 = this.turnstileVenues.option('value').id;
      filters.push(['venueID', '=', id2.toString()]);
      filters.push('and');
    }

    if (this.turnStileType != null && this.turnStileType == 'summary') {
      if (this.includeTurnstileAddress) {
        var id = this.turnstileAddress.option('value').id;
        let address = String(id);
        if (address != undefined && id > 0) {
          filters.push(['turnstileAddress', '=', address]);
          filters.push('and');
        }
        filters.push([
          this.dateColumnName,
          '>=',
          this.formatDate(this.startDate.option('value'), 0)
        ]);
        filters.push('and');
        filters.push([
          this.dateColumnName,
          '<',
          this.formatDate(this.endDate.option('value'), 999)
        ]);
      }
    } else {
      if (this.inventoryPageCall) {
        filters.push(this.formatDate(this.startDate.option('value'), 0));
        filters.push(this.formatDate(this.endDate.option('value'), 999));
      } else {
        if (this.includeDateAndTimeFilter) {
          filters.push([
            this.dateColumnName,
            '>=',
            this.getDate(this.startDate.option('value'), 0) +
              this.getTime(this.startTime.option('value'), 0)
          ]);
          filters.push('and');
          filters.push([
            this.dateColumnName,
            '<',
            this.getDate(this.endDate.option('value'), 999) +
              this.getTime(this.endTime.option('value'), 999)
          ]);
        } else {
          filters.push([
            this.dateColumnName,
            '>=',
            this.formatDate(this.startDate.option('value'), 0)
          ]);
          filters.push('and');
          filters.push([
            this.dateColumnName,
            '<',
            this.formatDate(this.endDate.option('value'), 999)
          ]);
        }
      }
    }

    if (this.turnStileType != null && this.turnStileType != 'summary') {
      filters.push('and');
      filters.push(['type', '=', this.turnStileType]);
    }

    if (this.includeProviders) {
      let providers = [];
      let providerFilters = Array<any>();
      if (!this.inventoryPageCall) {
        providers =
          this.providers.option('selectedItems').length > 0
            ? this.providers.option('selectedItems').map((p: any) => {
                return p.providerName;
              })
            : [];
        providers.forEach((item: any, i: number) => {
          providerFilters.push([this.providerColumnName, '=', item]);
          if (i < providers.length - 1) providerFilters.push('or');
        });

        if (providerFilters.length > 0) {
          filters.push('and');
          filters.push([providerFilters]);
        }
      } else {
        providers =
          this.providers.option('selectedItems').length > 0
            ? this.providers.option('selectedItems').map((p: any) => {
                return p.providerId;
              })
            : [];
        providers.forEach((item: any, i: number) => {
          providerFilters.push(item);
        });

        if (providerFilters.length > 0) {
          filters.push(providerFilters);
        } else {
          this.providersDataSource.length > 0
            ? this.providersDataSource.forEach((item: any, i: number) => {
                providerFilters.push(item.providerId);
              })
            : filters.push(null);
          if (providerFilters.length > 0) {
            filters.push(providerFilters);
          }
        }
      }
    }

    if (this.includeAgents) {
      let agents =
        this.agents.option('selectedItems').length > 0
          ? this.agents.option('selectedItems').map((a: any) => a.name)
          : [];
      let agentFilters = Array<any>();
      agents.forEach((item: any, i: number) => {
        agentFilters.push(['AgentName', '=', item]);
        if (i < agents.length - 1) agentFilters.push('or');
      });
      if (agentFilters.length > 0) {
        filters.push('and');
        filters.push([agentFilters]);
      }
    }

    if (this.includeChannels) {
      let channels =
        this.channels.option('selectedItems').length > 0
          ? this.channels.option('selectedItems').map((a: any) => a.name)
          : [];
      let channelFilters = Array<any>();
      channels.forEach((item: any, i: number) => {
        channelFilters.push([this.channelColumnName, '=', item]);
        if (i < channels.length - 1) channelFilters.push('or');
      });
      if (channelFilters.length > 0) {
        filters.push('and');
        filters.push([channelFilters]);
      }
    }

    if (this.includeProducts) {
      let products = [];
      let productFilters = Array<any>();
      if (!this.inventoryPageCall) {
        products =
          this.products.option('selectedItems').length > 0
            ? this.products.option('selectedItems').map((a: any) => a.name)
            : [];
        products.forEach((item: any, i: number) => {
          productFilters.push(['ProductName', '=', item]);
          productFilters.push('or');
          productFilters.push(['BundleName', '=', item]);
          if (i < products.length - 1) productFilters.push('or');
        });
        if (productFilters.length > 0) {
          filters.push('and');
          filters.push([productFilters]);
        }
      } else {
        products =
          this.products.option('selectedItems').length > 0
            ? this.products.option('selectedItems').map((a: any) => {
                return a.id;
              })
            : [];
        products.forEach((item: any, i: number) => {
          productFilters.push(item);
        });
        if (productFilters.length > 0) {
          filters.push(productFilters);
        } else {
          this.productsDataSource.length > 0
            ? this.productsDataSource.forEach((item: any, i: number) => {
                productFilters.push(item.id);
              })
            : filters.push(null);
          if (productFilters.length > 0) {
            filters.push(productFilters);
          }
        }
      }
    }

    this.$emit('submit', {
      filters: filters,
      endDate: this.endDate.option('value')
    });
  }

  public getDate(date: any, msec: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();

    let newDate =
      year +
      '-' +
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '-' +
      (day < 10 ? '0' + day : day);
    return newDate;
  }

  public getTime(date: any, msec: any) {
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = msec == 0 ? '00' : '59';

    let newTime =
      'T' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      seconds;

    return newTime;
  }
  public formatDate(date: any, msec: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = msec == 0 ? '00' : '59';

    let newDate =
      year +
      '-' +
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '-' +
      (day < 10 ? '0' + day : day) +
      'T' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      seconds;
    return newDate;
  }

  public formatDateTime(date: any, msec: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = msec == 0 ? '00' : '59';

    let newDate =
      year +
      '-' +
      (month + 1 < 10 ? '0' + (month + 1) : month + 1) +
      '-' +
      (day < 10 ? '0' + day : day) +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      seconds +
      '';
    return newDate;
  }
}
