let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Purchase Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'number',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'number',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'PaymentProvider',
    caption: 'Bank name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RetailPrice',
    caption: 'Retail Price',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'NetAdjAmount',
    caption: 'Net Adj Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'PromoDescription',
    caption: 'Promo Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CommissionToSeller',
    caption: 'CommissionToSeller',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    format: { type: 'currency', precision: 2 },
    allowFiltering: true,
  },
  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TaxAmount',
    caption: 'Tax',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'TotalSales',
    caption: 'Total Sales',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'AltPayment',
    caption: 'Alt Payment',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'TotalSales', summaryType: 'sum' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'TaxAmount', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'NetAdjAmount', summaryType: 'sum' },
  { selector: 'RetailPrice', summaryType: 'sum' },
  { selector: 'CommissionToSeller', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
