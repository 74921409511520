import { IReportService } from './IReportService';
import { AxiosClient } from '@/client/axios';
import { CurrentEnvironment, ICurrentEnvironment } from '@/config/environment';
export class ReportService implements IReportService {
  private client: AxiosClient;
  private env: ICurrentEnvironment = new CurrentEnvironment() as ICurrentEnvironment;
  constructor() {
    this.client = new AxiosClient();
  }

  public async getSalesDetailReport(loadOptions: any) {
    return await this.getReportData('sales-detail', loadOptions);
  }
  public async getAccessSummaryReport(loadOptions: any) {
    return await this.getReportData('access-summary', loadOptions);
  }
  public async getBankoutTenderDetailReport(loadOptions: any) {
    return await this.getReportData('bankout-tender-detail', loadOptions);
  }
  public async getGuestContactInfoReport(loadOptions: any) {
    return await this.getReportData('guestx-contact-information', loadOptions);
  }
  public async getInventoryCalendarReport(loadOptions: any) {
    return await this.getInventoryReportData('inventory-calendar', loadOptions);
  }
  public async getBankoutTenderSummaryReport(loadOptions: any) {
    return await this.getReportData('bankout-tender-summary', loadOptions);
  }
  public async getManifestReport(loadOptions: any) {
    return await this.getReportData('manifest', loadOptions);
  }
  public async getSalesRedeemedDetailReport(loadOptions: any) {
    return await this.getReportData('sales-redeemed-detail', loadOptions);
  }
  public async getSalesSummaryReport(loadOptions: any) {
    return await this.getReportData('sales-summary', loadOptions);
  }
  public async getTenderDetailReport(loadOptions: any) {
    return await this.getReportData('tender-detail', loadOptions);
  }
  public async getTenderSummaryReport(loadOptions: any) {
    return await this.getReportData('tender-summary', loadOptions);
  }
  public async getSalesRedeemedSummaryReport(loadOptions: any) {
    return await this.getReportData('sales-redeemed-summary', loadOptions);
  }
  public async getCityPassDetailReport(loadOptions: any) {
    return await this.getReportData('citypass-detail', loadOptions);
  }
  public async getDetailTurnStileEventReport(loadOptions: any) {
    return await this.getReportData('turnstile-detail', loadOptions);
  }
  public async getDetailTurnStileErrorReport(loadOptions: any) {
    return await this.getReportData('turnstile-detail', loadOptions);
  }
  public async getKioskUpgradeDetailReport(loadOptions: any) {
    return await this.getReportData('kiosk-upgrade-detail', loadOptions);
  }
  public async getVoucherReceivablesDetailReport(loadOptions: any) {
    return await this.getReportData('voucher-receivables-detail', loadOptions);
  }
  public async getVoucherReceivablesSummaryReport(loadOptions: any) {
    return await this.getReportData('voucher-receivables-summary', loadOptions);
  }
  public async getTaxesDueSummaryReport(loadOptions: any) {
    return await this.getReportData('taxes-due-summary', loadOptions);
  }
  public async getTaxesDueDetailReport(loadOptions: any) {
    return await this.getReportData('taxes-due-detail', loadOptions);
  }

  public async getPromotionsRedeemedDetail(loadOptions: any) {
    return await this.getReportData('promotions-redeemed-detail', loadOptions);
  }
  public async getPromotionsRedeemedSummary(loadOptions: any) {
    return await this.getReportData('promotions-redeemed-summary', loadOptions);
  }

  public async getSummaryTurnStileEventReport(loadOptions: any) {
    return await this.getReportData('turnstile-summary', loadOptions);
  }
  public async getSummaryTurnStileErrorReport(loadOptions: any) {
    return await this.getReportData('turnstile-summary-error', loadOptions);
  }

  public async getSalesNotRedeemedDetailReport(loadOptions: any) {
    return await this.getReportData('sales-not-redeemed-detail', loadOptions);
  }
  public async getSalesNotRedeemedSummaryReport(loadOptions: any) {
    return await this.getReportData('sales-not-redeemed-summary', loadOptions);
  }

  public async getReceivableDueDetailReport(loadOptions: any) {
    return await this.getReportData('receivable-due-detail', loadOptions);
  }
  public async getReceivableDueSummaryReport(loadOptions: any) {
    return await this.getReportData('receivable-due-summary', loadOptions);
  }
  public async getPayableDueDetailReport(loadOptions: any) {
    return await this.getReportData('receivable-due-detail', loadOptions);
  }
  public async getPayableDueSummaryReport(loadOptions: any) {
    return await this.getReportData('payable-due-summary', loadOptions);
  }
  public async getAdvanceSalesDetailReport(loadOptions: any) {
    return await this.getReportData('advance-sales-detail', loadOptions);
  }
  public async getAdvanceSalesSummaryReport(loadOptions: any) {
    return await this.getReportData('advance-sales-summary', loadOptions);
  }
  public async getPromotionsSoldDetailReport(loadOptions: any) {
    return await this.getReportData('promotions-sold-detail', loadOptions);
  }
  public async getPromotionsSoldSummaryReport(loadOptions: any) {
    return await this.getReportData('promotions-sold-summary', loadOptions);
  }
  public async getRedemptionDetailReport(loadOptions: any) {
    return await this.getReportData('redemption-detail', loadOptions);
  }
  public async getRedemptionSummaryReport(loadOptions: any) {
    return await this.getReportData('redemption-summary', loadOptions);
  }
  public async getValidationExperienceSummaryReport(loadOptions: any) {
    return await this.getReportData(
      'validation-experience-summary',
      loadOptions
    );
  }
  public async getCategorySalesDetailReport(loadOptions: any) {
    return await this.getReportData('category-sales-detail', loadOptions);
  }
  public async getTransactionsDetailByTicketReport(loadOptions: any) {
    return await this.getReportData(
      'transactions-detail-by-ticket',
      loadOptions
    );
  }

  private async getReportData(reportUrl: string, loadOptions: any) {
    let result: any = [];
    loadOptions = JSON.stringify({ loadOptions });
    let baseURL = this.env.functionAppbaseUrl + reportUrl;
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    };
    await this.client
      .post(baseURL, loadOptions, headers)
      .then(function(response) {
        result = response.data;
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }

  private async getInventoryReportData(reportUrl: string, loadOptions: any) {
    let result: any = [];
    let baseURL =
      'https://localhost:44309/api/Report/GetInventoryCalendarVueReport';
    let config = {
      headers: {
        appInstanceKey:
          'RABUAEMALQBHAHUARQBzAFQAeABFsG6/WnO6OU75bo1xwAUkshW2B/xkar8g0u6AeS50uyZDDunAvh5Q/F+//9aFKqBLtHtpAq7cP6k2RDvxybQbp+p5sSTES1y1KCn+BwDyir8chu+h9G0tY2BU9+9i6khEUdRFRfEvt37/5Rzdwd9iUc9pADoDkizl/Z/V9gTXwLKAQulJgOjApflsdGIrEZ+RNPJ8S6/2iZdu8D//M0w2lWJP9SUWGc1XKP9wFocedhQU7NyABiWKxCpTN9cfbi4=',
      },
    };
    await this.client
      .posts(baseURL, loadOptions, config)
      .then(function(response) {
        result = response.data;
        return result;
      })
      .catch(function(error) {
        result = error;
        return result;
      });
    return result;
  }
}
