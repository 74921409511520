export const columns = [
  {
    dataField: 'turnstileAddress',
    caption: 'Turnstile Address',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'errorType',
    caption: 'Type Of Error',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'totalCount',
    caption: 'Error Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  }
];
