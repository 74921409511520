export const columns = [
    {
      dataField: 'RedeemptionDate',
      caption: 'Period Date',
      dataType: 'datetime',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'RedeemptionTimePeriod',
      caption: 'Redemption Time Period',
      dataType: 'hour',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'RedeemptionTime',
        caption: 'Redemption Time',
        dataType: 'time',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'OrderNumber',
      caption: 'Order Number',
      dataType: 'number',
      alignment: 'left',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'OrderProductId',
        caption: 'Ticket Id',
        dataType: 'number',
        alignment: 'left',
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'Barcode',
        caption: 'Barcode',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'Status',
        caption: 'Status',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'AgentName',
        caption: 'Agent Name',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'DeviceName',
        caption: 'Device Name',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'Description',
        caption: 'Description',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'SellerName',
      caption: 'Seller',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'ProviderName',
      caption: 'Provider',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'RetailPrice',
        caption: 'Retail Price',
        dataType: 'number',
        format: { type: 'currency', precision: 2 },
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'Tax',
        caption: 'Tax',
        dataType: 'number',
        format: { type: 'currency', precision: 2 },
        visible: true,
        allowFiltering: true
    },
    {
        dataField: 'ProductName',
        caption: 'Product Name',
        dataType: 'string',
        visible: true,
        allowFiltering: true
      },
    {
      dataField: 'BundleName',
      caption: 'Bundle Name',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'PromoDescription',
        caption: 'Promo Description',
        dataType: 'string',
        visible: true,
        allowFiltering: true
    },
    {
      dataField: 'CompPrice',
      caption: 'Comp Price',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
      alignment: 'left',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'CompCount',
      caption: 'Comp Count',
      dataType: 'number',
      alignment: 'left',
      visible: true,
      allowFiltering: true
    },
    {
        dataField: 'Redeemer',
        caption: 'Redeemer',
        dataType: 'string',
        visible: true,
        allowFiltering: true
      },
    {
      dataField: 'OrderTicketType',
      caption: 'Ticket Type',
      dataType: 'string',
      visible: true,
      alignment: 'left',
      allowFiltering: true,
    },
  ];
  
  let summary = [
    { selector: 'OrderNumber', summaryType: 'count' },
    { selector: 'RetailPrice', summaryType: 'sum' },
    { selector: 'CompPrice', summaryType: 'sum' },
    { selector: 'CompCount', summaryType: 'sum' },
    { selector: 'Tax', summaryType: 'sum' },
  ];
  
  export default { columns: columns, summary: summary };
  