export const columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PaymentProvider',
    caption: 'Bank name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channels',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  
  {
    dataField: 'PromotionType',
    caption: 'Promotion Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PromotionName',
    caption: 'Promotion Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket count',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BasePrice',
    caption: 'Base Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PromotionSummaryAmount',
    caption: 'Promotion Summary Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'OtherAdjustments',
    caption: 'Other Adjustments',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'CompPrice',
    caption: 'Comp Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'CompCount',
    caption: 'Comp Count',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  }
];

let summary = [
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'RowCount', summaryType: 'sum' },
  { selector: 'BasePrice', summaryType: 'sum' },
  { selector: 'PromotionSummaryAmount', summaryType: 'sum' },
  { selector: 'OtherAdjustments', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'CompPrice', summaryType: 'sum' },
  { selector: 'CompCount', summaryType: 'sum' }
];

export default { columns: columns, summary: summary };
