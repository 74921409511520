let columns = [
  {
    dataField: 'productName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'timeSlotName',
    caption: 'Time Slot Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'startTime',
    caption: 'Start Date Time',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'endTime',
    caption: 'End Date Time',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'totalSold',
    caption: 'Total Quantity Sold',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'total',
    caption: 'Total Quantity',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'totalRemaining',
    caption: 'Remaining Quantity',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  }
];

let summary = [
  { selector: 'total', summaryType: 'sum' },
  { selector: 'totalRemaining', summaryType: 'sum' },
  { selector: 'totalSold', summaryType: 'sum' }
];

export default { columns: columns, summary: summary };
