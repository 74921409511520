export const columns = [
  {
    dataField: 'turnstileAddress',
    caption: 'Turnstile Address',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'groupId',
    caption: 'Group/Single',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
    customizeText: function(cellInfo: any) {
      if (
        typeof cellInfo.value == undefined ||
        cellInfo.value == '' ||
        cellInfo.value == null
      )
        return 'Single';
      else return 'Group';
    }
  },
  {
    dataField: 'status',
    caption: 'Validation',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'result',
    caption: 'Turnstile',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'data.email',
    caption: 'Force Who',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'data.reason',
    caption: 'Force Why',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'datetime',
    caption: 'Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  }
];
