import { IReportItem } from '@/types';

export const addPurchaseDateLocal = (
  data: IReportItem[],
  fieldNameFrom?: string | undefined,
  fieldNameTo?: string | undefined
) => {
  const fieldNameFromCreate: string = fieldNameFrom || 'PurchaseDate';
  const fieldNameToCreate: string = fieldNameTo || 'PurchaseDateLocal';
  const newData = data.map((item: any) => {
    if (!item[fieldNameToCreate]) {
      const objDate = item[fieldNameFromCreate];
      const date = new Date(objDate);
      const y = date.getFullYear();
      const m = ('0' + (date.getMonth() + 1)).slice(-2);
      const d = ('0' + date.getDate()).slice(-2);
      const dateString = `${y}-${m}-${d}`;
      item[fieldNameToCreate] = dateString;
    }

    return item;
  });

  return newData;
};
