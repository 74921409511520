
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGrid } from 'devextreme-vue/ui/data-grid';
import { IDxInstance } from '@/types';
import { columns } from './config';
import { DxDateBox, DxSelectBox, DxTagBox } from 'devextreme-vue';
import { IReportService, ReportServiceFactory } from '@/api';
import ReportParameterForm from '@/views/reports/ReportParameterForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

@Component({
  name: 'BankoutTenderDetail',
  components: {
    'gx-bc': Breadcrumbs,
    'gx-rpf': ReportParameterForm,
  },
})
export default class BankoutTenderDetail extends Vue {
  public $refs: Vue['$refs'] & { grid?: DxDataGrid } = {};
  private columns: any = columns;
  private load: boolean = false;
  private totals: any[] = [0, 0, 0];
  private exportFileName: string =
    'BankoutTenderDetail_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().toLocaleTimeString();
  private reportService: IReportService = ReportServiceFactory.getInstance() as IReportService;
  private dataSource: any = {
    store: new CustomStore({
      load: (loadOptions) => {
        if (!this.load) {
          this.load = true;
          return Promise.resolve({ data: [], totalCount: 0 });
        }
        return this.fetchData(loadOptions);
      },
    }),
  };

  // ref getter
  private get grid() {
    return (this.$refs.grid as IDxInstance).instance;
  }

  private onContentReady() {
    this.totals = [0, 0];

    this.totals[0] = this.grid.getTotalSummaryValue('TotalAmount');
    this.totals[1] = this.grid.getTotalSummaryValue('OrderNumber');
    let totalSales = (this.totals[0] && this.totals[0].toFixed(2)) || undefined;
    this.totals[0] = totalSales;
    this.$emit('update', this.totals);
  }

  async fetchData(loadOptions: any): Promise<any> {
    /*
      Configure loadOptions to return totals.  We are not using the totals in the grid but will
      take advantage of being able to have the query return totls that we pull out of the payload upon return
    */
    loadOptions.totalSummary = [
      { selector: 'TotalAmount', summaryType: 'sum' },
      { selector: 'OrderNumber', summaryType: 'count' },
      { selector: 'TicketCount', summaryType: 'sum' },
    ];

    var response = await this.reportService.getBankoutTenderDetailReport(
      loadOptions
    );
    this.totals = response.data.summary || [0, 0];
    if (response.summary == undefined) {
      response.summary = [0, 0];
    }
    return response;
  }

  public onExportToExcel() {
    this.grid.exportToExcel(false);
  }

  public onShowHideColumns() {
    this.grid.showColumnChooser();
  }

  public onViewReport(args: any) {
    // set filters & fetch (reload  calls store.load which is defined above)
    this.grid.getDataSource().filter(args.filters);
    this.grid.getDataSource().reload();
  }
}
