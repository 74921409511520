export const columns = [
  {
    dataField: 'turnstileAddress',
    caption: 'Turnstile Address',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'reason',
    caption: 'Error',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'datetime',
    caption: 'Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  }
];
