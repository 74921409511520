var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { ref: "card", staticClass: "px-0 py-0" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "mb-2",
              attrs: { dense: "", flat: "", color: "grey lighten-3" }
            },
            [_c("v-toolbar-title", [_vm._v(_vm._s(_vm.$route.name))])],
            1
          ),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { align: "start", justify: "start" } },
                [
                  _vm.includeDateFilterDuplicated
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Start Date:")]
                                  ),
                                  _c("dx-date-box", {
                                    ref: "startRef",
                                    attrs: {
                                      min: _vm.minStartDate,
                                      value: _vm.start,
                                      type: _vm.startDateType
                                    },
                                    on: {
                                      "value-changed": _vm.onStartDateChange
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("End Date:")]
                                  ),
                                  _c("dx-date-box", {
                                    ref: "endRef",
                                    attrs: {
                                      value: _vm.end,
                                      type: _vm.endDateType
                                    },
                                    on: { "value-changed": _vm.onEndDateChange }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.includeDateAndTimeFilterDuplicated
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Start Time:")]
                                  ),
                                  _c("dx-date-box", {
                                    ref: "startTimeRef",
                                    attrs: { value: _vm.start, type: "time" },
                                    on: {
                                      "value-changed": _vm.onStartDateChange
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("End Time:")]
                                  ),
                                  _c("dx-date-box", {
                                    ref: "endTimeRef",
                                    attrs: { value: _vm.end, type: "time" },
                                    on: { "value-changed": _vm.onEndDateChange }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.includeSeller
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Seller:")]
                                  ),
                                  _c("dx-select-box", {
                                    ref: "sellerRef",
                                    attrs: {
                                      "data-source": _vm.sellersDataSource,
                                      value: _vm.selectedSeller,
                                      placeholder: "All Selected",
                                      "display-expr": "name"
                                    },
                                    on: {
                                      "value-changed":
                                        _vm.sellerDataSourceChange
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeProviders
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Providers:")]
                                  ),
                                  _c("dx-tag-box", {
                                    ref: "providersRef",
                                    attrs: {
                                      "show-clear-button": true,
                                      "data-source": _vm.providersDataSource,
                                      "display-expr": "providerName",
                                      "value-expr": "providerId",
                                      placeholder: "All Selected"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeAgents
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Agents:")]
                                  ),
                                  _c("dx-tag-box", {
                                    ref: "agentsRef",
                                    attrs: {
                                      "show-clear-button": true,
                                      "data-source": _vm.agentsDataSource,
                                      placeholder: "All Selected",
                                      "display-expr": "name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeChannels
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Channels:")]
                                  ),
                                  _c("dx-tag-box", {
                                    ref: "channelsRef",
                                    attrs: {
                                      "show-clear-button": true,
                                      "data-source": _vm.channelsDataSource,
                                      placeholder: "All Selected",
                                      "display-expr": "name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeProducts
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Products:")]
                                  ),
                                  _c("dx-tag-box", {
                                    ref: "productsRef",
                                    attrs: {
                                      "show-clear-button": true,
                                      "data-source": _vm.productsDataSource,
                                      "display-expr": "name",
                                      "value-expr": "id",
                                      placeholder: "All Selected"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeTurnstileAddress
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Turnstile Address:")]
                                  ),
                                  _c("dx-select-box", {
                                    ref: "turnstileAddressRef",
                                    attrs: {
                                      "data-source":
                                        _vm.turnstileAddressDataSource,
                                      value: _vm.selectedTurnstileAddress,
                                      placeholder: "All Selected",
                                      "display-expr": "name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.includeTurnstileVenues
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "subtitle-2 font-weight-light"
                                    },
                                    [_vm._v("Choose Venue:")]
                                  ),
                                  _c("dx-select-box", {
                                    ref: "venueRef",
                                    attrs: {
                                      "data-source": _vm.venuesDataSource,
                                      value: _vm.selectedVenue,
                                      placeholder: "All Selected",
                                      "display-expr": "name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { align: "end", justify: "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse",
                      attrs: { cols: "3" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "subtitle-2 font-weight-light" },
                        [
                          _vm._v(
                            _vm._s(this.summaryColumnOne) +
                              _vm._s(_vm.rptSummaryColOneValue)
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse",
                      attrs: { cols: "3" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "subtitle-2 font-weight-light" },
                        [
                          _vm._v(
                            _vm._s(this.summaryColumnTwo) +
                              " " +
                              _vm._s(_vm.rptSummaryColTwoValue)
                          )
                        ]
                      )
                    ]
                  ),
                  _vm.includesummaryColumnThree
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-row-reverse",
                          attrs: { cols: "3" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "subtitle-2 font-weight-light" },
                            [
                              _vm._v(
                                _vm._s(this.summaryColumnThree) +
                                  _vm._s(_vm.rptSummaryColThreeValue)
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row-reverse",
                      attrs: { cols: "3" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.viewReportBtnDisable },
                          on: { click: _vm.onViewReport }
                        },
                        [_vm._v("View Report")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }