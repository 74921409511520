
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGrid } from 'devextreme-vue/ui/data-grid';
import { IDxInstance } from '@/types';
import { columns } from './config';
import { DxDateBox, DxSelectBox, DxTagBox } from 'devextreme-vue';
import { IReportService, ReportServiceFactory } from '@/api';
import ReportParameterForm from '@/views/reports/ReportParameterForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

@Component({
  name: 'Manifest',
  components: {
    'gx-bc': Breadcrumbs,
    'gx-rpf': ReportParameterForm
  }
})
export default class Manifest extends Vue {
  public $refs: Vue['$refs'] & { grid?: DxDataGrid } = {};
  private columns: any = columns;
  private load: boolean = false;
  private totals: any[] = [0, 0, 0];
  private exportFileName: string =
    'Manifest_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().toLocaleTimeString();
  private reportService: IReportService = ReportServiceFactory.getInstance() as IReportService;
  private dataSource: any = {
    store: new CustomStore({
      load: loadOptions => {
        if (!this.load) {
          this.load = true;
          return Promise.resolve({ data: [], totalCount: 0 });
        }
        return this.fetchData(loadOptions);
      }
    })
  };
  // ref getter
  private get grid() {
    return (this.$refs.grid as IDxInstance).instance;
  }

  private onContentReady() {
    this.totals = [0,0];
    this.totals[0] = this.grid.getTotalSummaryValue('OrderId');
    this.totals[1] = this.grid.getTotalSummaryValue('OrderId');

    this.$emit('update', this.totals);
  }

  async fetchData(loadOptions: any): Promise<any> {
    loadOptions.totalSummary = [{ selector: 'OrderId', summaryType: 'count' }];
    var response = await this.reportService.getManifestReport(loadOptions);
    if (
      response == undefined ||
      response == '' ||
      response.summary == undefined
    ) {
      response.summary = [0];
    }
    return response;
  }

  public onExportToExcel() {
    this.grid.exportToExcel(false);
  }

  public onShowHideColumns() {
    this.grid.showColumnChooser();
  }

  public onViewReport(args: any) {
    this.grid.getDataSource().filter(args.filters);
    this.grid.getDataSource().reload();
  }
}
