
import Vue from 'vue';
import Component from 'vue-class-component';
import { authService } from '@/views/auth0/Auth0ServiceInst';
import router from '@/router';

@Component({
  name: 'AuthoCallBack',
})
export default class AuthoCallBack extends Vue {
  created() {
    authService.handleLoginCallback();
  }
}
