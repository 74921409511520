let columns = [
  {
    dataField: 'ArrivalDate',
    caption: 'Arrival Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ArrivalTimeSlot',
    caption: 'Arrival Time Slot',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'AssignedToSubSlot',
    caption: 'Assigned to Sub Slot',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PurchaseDate',
    caption: 'Sales Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PaymentProvider',
    caption: 'Bank name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'number',
    visible: true,
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'OrderTicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail',
    dataType: 'number',
    visible: true,
    format: { type: 'currency',precision: 2},
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'NetAdjAmount',
    caption: 'Net Adj Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency',precision: 2},
    alignment:'left' ,
    allowFiltering: true,
    },
  {
    dataField: 'PromoDescription',
    caption: 'Promo Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'Tax',
    caption: 'Tax',
    dataType: 'number',
    visible: true,
    format: { type: 'currency',precision: 2},
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'TotalSales',
    caption: 'Sales Total',
    dataType: 'number',
    visible: true,
    format: { type: 'currency',precision: 2},
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'Barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'ConsignmentId',
    caption: 'ConsignmentId',
    dataType: 'number',
    visible: true,
    alignment:'left' ,
    allowFiltering: true
  },
  {
    dataField: 'Non-Tender',
    caption: 'Non-Tender',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
];

let summary = [
  { selector: 'TotalSales', summaryType: 'sum' },
  { selector: 'OrderNumber', summaryType: 'count' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'NetAdjAmount', summaryType: 'sum' },
  { selector: 'RetailPrice', summaryType: 'sum' }
];

export default { columns: columns, summary: summary };


