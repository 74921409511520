let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Purchase Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PurchaseDate',
    caption: 'Purchase Time',
    dataType: 'datetime',
    format: 'hh:mm:ss',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RedeemptionDate',
    caption: 'Redeemption Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RedeemptionDate',
    caption: 'Redeemption Time',
    dataType: 'datetime',
    format: 'hh:mm:ss',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'AgentName',
    caption: 'Agent Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'DeviceName',
    caption: 'Device Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RetailPrice',
    caption: 'Retail Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NetAdjAmount',
    caption: 'Net Adj Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PromoDescription',
    caption: 'Promo Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Description',
    caption: 'Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Tax',
    caption: 'Tax',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TotalSales',
    caption: 'Total Sales',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderTicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'Status',
    caption: 'Status',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'Barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ConsignmentId',
    caption: 'ConsignmentId',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompPrice',
    caption: 'Comp Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompCount',
    caption: 'Comp Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumPrice',
    caption: 'Premium Price',
    dataType: 'Boolean',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'PremiumAdultCount',
    caption: 'Premium Adult Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumYouthCount',
    caption: 'Premium Youth Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumSeniorCount',
    caption: 'Premium Senior Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumAdultCount',
    caption: 'Non Premium Adult Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumYouthCount',
    caption: 'Non Premium Youth Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumSeniorCount',
    caption: 'Non Premium Senior Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketTenderBarcode',
    caption: 'Ticket Tender Barcode',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketTenderAmount',
    caption: 'Ticket Tender Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductType',
    caption: 'Product Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ClientName',
    caption: 'Client Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Redeemer',
    caption: 'Redeemer',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PromoPurchase',
    caption: 'Promo Purchase',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'TotalSales', summaryType: 'sum' },
  { selector: 'OrderNumber', summaryType: 'count' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'NetAdjAmount', summaryType: 'sum' },
  { selector: 'RetailPrice', summaryType: 'sum' },
  { selector: 'CompPrice', summaryType: 'sum' },
  { selector: 'CompCount', summaryType: 'sum' },
  { selector: 'PremiumTotalCount', summaryType: 'sum' },
  { selector: 'PremiumAdultCount', summaryType: 'sum' },
  { selector: 'PremiumYouthCount', summaryType: 'sum' },
  { selector: 'PremiumSeniorCount', summaryType: 'sum' },
  { selector: 'NonPremiumAdultCount', summaryType: 'sum' },
  { selector: 'NonPremiumYouthCount', summaryType: 'sum' },
  { selector: 'NonPremiumSeniorCount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
