export const columns = [
  {
    dataField: 'PurchaseDate',
    caption: 'Transaction Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'OrderId',
    caption: 'Order Id',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'FirstName',
    caption: 'First Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'LastName',
    caption: 'Last Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'GuestEmail',
    caption: 'Guest Email',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PhoneNumber',
    caption: 'Phone Number',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ArrivalDate',
    caption: 'Arrival Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TimeSlotName',
    caption: 'Time Slot Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  }
];
