let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Date',
    dataType: 'datetime',
    format: 'MM/dd/yyyy',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PaymentProvider',
    caption: 'Bank name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Tax',
    caption: 'Tax',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompPrice',
    caption: 'Comp Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompCount',
    caption: 'Comp Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
    alignment: 'left',
  },
];

let summary = [
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'CompPrice', summaryType: 'sum' },
  { selector: 'CompCount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
