export const columns = [
  {
    dataField: 'turnstileAddress',
    caption: 'Turnstile Address',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ticketScanned',
    caption: 'Total Tickets Scanned',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ticketRedeemed',
    caption: 'Total Tickets Redeemed',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'totalPasses',
    caption: 'Total Passes ',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'singleSuccess',
    caption: 'Total Single Success',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'singleTimeout',
    caption: 'Total Single Timeout',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'groupTicketSuccess',
    caption: 'Total Group Tickets Success',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'gruopSuccess',
    caption: 'Total Group Full Success',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'groupPartialSuccess',
    caption: 'Total Group Partial Success ',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
];
