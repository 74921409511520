
import _ from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'Breadcrumbs'
})
export default class Breadcrumbs extends Vue {
  breadcrumbs: Array<any> = [];

  mounted() {
    let pathurl:any;
    let pathArray = this.$route.path.split('/');
    pathArray.shift();
    this.breadcrumbs = pathArray.map((item: any, idx: any, arr: any) => {
     switch(item){
       case 'finance':
            pathurl = pathArray[0] +'/'+pathArray[1] + '/sales-summary'; 
         break;
         case 'marketing':
            pathurl = pathArray[0] +'/'+pathArray[1] + '/guest-contact-info'; 
         break;
         case 'operations':
            pathurl = pathArray[0] +'/'+pathArray[1] + '/access-summary'; 
         break;
          case 'reports':
            pathurl = pathArray[0] +'/'+ 'finance' + '/sales-summary'; 
         break;
         default :
            pathurl = pathArray[0] +'/'+ pathArray[1] + '/' + pathArray[2] ;
         break;
     }
      return { text: _.startCase(item.replace('-', ' ')), disabled: false, href: '/'+ pathurl };
    });
  }
}
