import { render, staticRenderFns } from "./PromotionsSoldDetail.vue?vue&type=template&id=c09bd3f0&"
import script from "./PromotionsSoldDetail.vue?vue&type=script&lang=ts&"
export * from "./PromotionsSoldDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c09bd3f0')) {
      api.createRecord('c09bd3f0', component.options)
    } else {
      api.reload('c09bd3f0', component.options)
    }
    module.hot.accept("./PromotionsSoldDetail.vue?vue&type=template&id=c09bd3f0&", function () {
      api.rerender('c09bd3f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/reports/finance/promotions-sold-detail/PromotionsSoldDetail.vue"
export default component.exports