export const columns = [
  {
    dataField: 'RedeemedDate',
    caption: 'Redeemed Date',
    dataType: 'date',
    visible: false,
    allowFiltering: true
  },
  {
    dataField: 'Hour',
    caption: 'Hour',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'AccessPointName',
    caption: 'Access Point Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'GoodCount',
    caption: 'Good Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BadCount',
    caption: 'Bad Count',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductTypeName',
    caption: 'Product Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'Redeemer',
    caption: 'Redeemer',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  }
];
