
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomStore from 'devextreme/data/custom_store';
import {
  DxDataGrid,
  DxSummary,
  DxTotalItem
} from 'devextreme-vue/ui/data-grid';
import { IDxInstance } from '@/types';
import config from './config';
import { DxDateBox, DxSelectBox, DxTagBox } from 'devextreme-vue';
import { IReportService, ReportServiceFactory } from '@/api';
import { IParameterService } from '@/api/IParameterService';
import { ParameterServiceFactory } from '@/api/ParameterServiceFactory';
import ReportParameterForm from '@/views/reports/ReportParameterForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

@Component({
  name: 'InventoryCalendar',
  components: {
    'gx-bc': Breadcrumbs,
    'gx-rpf': ReportParameterForm
  }
})
export default class InventoryCalendar extends Vue {
  public $refs: Vue['$refs'] & { grid?: DxDataGrid } = {};
  private columns: any = config.columns;
  private load: boolean = false;
  private totals: any[] = [0, 0, 0];
  private exportFileName: string =
    'InventoryCalendar_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().toLocaleTimeString();
  private reportService: IReportService = ReportServiceFactory.getInstance() as IReportService;
  private parameterService: IParameterService = ParameterServiceFactory.getInstance() as IParameterService;
  private dataSource: any = {
    store: new CustomStore({
      load: loadOptions => {
        if (!this.load) {
          this.load = true;
          return Promise.resolve({ data: [], totalCount: 0 });
        }
        return this.fetchData(loadOptions);
      }
    })
  };

  // ref getter
  private get grid() {
    return (this.$refs.grid as IDxInstance).instance;
  }

  async fetchData(loadOptions: any): Promise<any> {
    let response: any;
    let loadOptionsAgent: any;
    loadOptionsAgent = {
      StartDate: loadOptions.filter[0],
      EndDate: loadOptions.filter[1],
      ProviderIds: [41], //loadOptions.filter[2],
      ProductIds: loadOptions.filter[3]
    };

    response = await this.parameterService.getInventoryCalendar(
      loadOptionsAgent
    );
    if (response.summary == undefined || response === null) {
      response.summary = [0, 0, 0];
    }
    if (response.summary != null) {
      this.totals = [0, 0, 0];
      this.totals[0] = response.summary[2];
      this.totals[1] = response.summary[0];
      this.totals[2] = response.summary[1];
      this.$emit('update', this.totals);
    }
    return response;
  }

  public onExportToExcel() {
    this.grid.exportToExcel(false);
  }

  public onShowHideColumns() {
    this.grid.showColumnChooser();
  }

  public onViewReport(args: any) {
    // set filters & fetch (reload  calls store.load which is defined above)
    this.grid.getDataSource().filter(args.filters);
    this.grid.getDataSource().reload();
  }
}
