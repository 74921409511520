export const columns = [
  {
    dataField: 'PurchaseDate',
    caption: 'Purchase Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'FirstName',
    caption: 'First Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'LastName',
    caption: 'Last Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'GuestEmail',
    caption: 'Guest Email',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'GuestPostalCode',
    caption: 'Guest Postal Code',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PhoneNumber',
    caption: 'Phone Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'EventDate',
    caption: 'Event Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'OptIn',
    caption: 'Opt IN',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'PromoPurchase',
    caption: 'Promo Purchase',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  }
];
