
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGrid } from 'devextreme-vue/ui/data-grid';
import { IDxInstance, IReportItem } from '@/types';
import { IReportService, ReportServiceFactory } from '@/api';
import ReportParameterForm from '@/views/reports/ReportParameterForm.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import config from './config';
import { addPurchaseDateLocal } from '@/utils/addPurchaseDateLocal';

@Component({
  name: 'SalesNotRedeemedSummary',
  components: {
    'gx-bc': Breadcrumbs,
    'gx-rpf': ReportParameterForm,
  },
})
export default class SalesNotRedeemedSummary extends Vue {
  public $refs: Vue['$refs'] & { grid?: DxDataGrid } = {};
  private columns: any = config.columns;
  private totals: any[] = [0, 0];
  private load: boolean = false;
  private exportFileName: string =
    'SalesNotRedeemedSummary_' +
    new Date().toLocaleDateString() +
    '_' +
    new Date().toLocaleTimeString();
  private endDate: any;
  private reportService: IReportService = ReportServiceFactory.getInstance() as IReportService;
  private dataSource: any = {
    store: new CustomStore({
      load: (loadOptions) => {
        if (!this.load) {
          this.load = true;
          return Promise.resolve({ data: [], totalCount: 0 });
        }

        return this.fetchData(loadOptions);
      },
    }),
  };

  private onContentReady() {
    this.totals = [0, 0];
    this.totals[0] = this.grid.getTotalSummaryValue('TotalSales');
    this.totals[1] = this.grid.getTotalSummaryValue('TicketCount');

    let totalSales = (this.totals[0] && this.totals[0].toFixed(2)) || undefined;
    this.totals[0] = totalSales;
    this.$emit('update', this.totals);
  }

  // ref getter
  private get grid() {
    return (this.$refs.grid as IDxInstance).instance;
  }

  async fetchData(loadOptions: any): Promise<any> {
    loadOptions.totalSummary = config.summary;
    loadOptions.endDate = this.endDate;
    var response = await this.reportService.getSalesNotRedeemedSummaryReport(
      loadOptions
    );
    if (response.summary == undefined) {
      response.summary = [0, 0];
    }

    const defaultData: IReportItem[] = [...response.data];
    const newData: IReportItem[] = addPurchaseDateLocal(defaultData);
    response.data = newData;

    return response;
  }

  public onExportToExcel() {
    this.grid.exportToExcel(false);
  }

  public onShowHideColumns() {
    this.grid.showColumnChooser();
  }

  public onViewReport(args: any) {
    this.grid.getDataSource().filter(args.filters);
    this.endDate = args.endDate;
    this.grid.getDataSource().reload();
  }
}
