var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "my-0",
      attrs: { dense: "", flat: "", color: "grey lighten-3" }
    },
    [
      _c("v-breadcrumbs", {
        staticClass: "px-0",
        attrs: { items: _vm.breadcrumbs, large: false, divider: "/" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }