export const columns = [
  {
    dataField: 'orderId',
    caption: 'Order ID',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'upgradeFrom',
    caption: 'Product From',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'upgradeTo',
    caption: 'Product To',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'visitDate',
    caption: 'Visit Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'waitTime',
    caption: 'Wait Time',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'datetime',
    caption: 'Purchase Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  }
];
