import { IParameterService } from './IParameterService';
import { ParameterService } from './ParameterService';
import { MockParameterService } from './mock/MockParameterService';


export class ParameterServiceFactory {
    static getInstance(mock: boolean = false) : IParameterService {
        if (mock) 
            return new MockParameterService() as IParameterService;
        else 
            return new ParameterService() as IParameterService;
    }
}